import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEOComponent from "../components/global/seo";
import LayoutComponent from "../components/global/layout";

const AboutPage = ({ data }) => {
  const avatarImage = getImage(data.imageSharp)

  return (
    <LayoutComponent>
      <SEOComponent title="About"
        metaTitle="About | Surinder Bhomra" ogType={`article`}
        metaDescription="About Surinder Bhomra - An experienced developer with over 15 years of experience developing pretty cool web and software applications for a variety of industries, including TV, retail, and corporate."
        shareImageIsLarge={true} />
      <div className="divide-y">
        <header className="pt-6 pb-8 space-y-2 md:space-y-5">
          <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
            About
          </h1>
          <p>Here is a page about me and how I have come to be.</p>
        </header>
        <div className="space-y-2 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:space-y-0">
          <div className="flex flex-col items-center pt-8 space-x-2">
            <div className="block items-center justify-between">
              <div className="flex justify-center items-center mb-3">
                <GatsbyImage image={avatarImage} className="gatsby-image-rounded" alt="Surinder Bhomra" />
              </div>
              <h3 className="pt-4 pb-2 text-2xl font-bold leading-8 tracking-tight">Surinder Bhomra</h3>
            </div>
            <div className="prose pt-4">
              <blockquote>Experienced developer with over 15 years experience, working alongside many industry sectors, developing pretty cool solutions for TV, retail and corporate.</blockquote>
            </div>
          </div>
          <div className="pt-8 pb-8 sm:pt-6 sm:pb-6 prose dark:prose-dark max-w-none xl:col-span-2">
            <p>Hi and welcome to my site.</p>
            <p>
              My name is Surinder Bhomra. This is a site for all things technical and anything else that may interest me.
              Have a browse and let me know what you think. I am always interested in feedback relating to any of my posts.
            </p>
            <p>
              Well, what can I tell you about me? I've been interested in technology at a young age from the day my Dad
              bought home our first computer. An <a href="http://chrisacorns.computinghistory.org.uk/Computers/A3000.html" rel="noopener noreferrer" target="_blank">Acorn Archimedes A3000</a>.
              Classic! I then went onto studying IT throughout my school years. My interest in learning the basics in HTML and
              programming eventually led me to Oxford Brookes University where I graduated with a BSc Information Systems in 2006.
              After University, I became a full-time programmer working in .NET technologies, mainly specialising in web and software applications.
            </p>
            <p>
              I am an experienced developer with around 15 years experience and throughout that time had the pleasure working alongside many
              industry sectors, developing pretty cool solutions for TV, retail and corporate. I love working in Microsoft based technologies and
              even though my language of choice is C#, I have been known to dabble in other technologies and adapt to what a project requires.
            </p>
            <p>
              I'm particularly interested in creating custom solutions that connect multiple platforms or APIs to form a cohesive piece of functionality 
              that works in unison. This could include connectors that allow data to be exchanged between HubSpot, Shopify, and a CMS, as well as 
              churning through a large dataset of products for use in in-house systems or large CRM platforms.
            </p>
            <p>
              I currently a lead a technical team at an awesome digital agency - <a href="http://www.syndicut.com/" rel="noopener noreferrer" target="_blank">Syndicut</a>, working
              on a wide variety of interesting and complex projects. So I could be working on anything from custom applications and website builds to
              using a multitude of platforms.
            </p>
            <p>
              When I am not sat down blogging with a cup of tea and a few chocolate Hobnob biscuits in hand, I am a big film enthusiast and go to the cinema
              any chance I get. I've been also known to go on random hikes and drive down winding roads in my Audi to get there!
            </p>
          </div>
        </div>
      </div>
    </LayoutComponent>
  )
}

export const query = graphql`
  query {
    imageSharp(fluid: {originalName: {eq: "logo-me.jpg"}}) {
      fluid {
        src
        originalName
      }
      gatsbyImageData(width: 200, placeholder: BLURRED)
    }
  }
`

export default AboutPage
